import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const PlaneSort = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Parse current query parameters
  const searchParams = new URLSearchParams(location.search);
  const initialSort = searchParams.get("sort") || "";

  const [sort, setSort] = useState(initialSort);

  // Update the URL query parameter when sort changes
  const handleSortChange = (e) => {
    const newSort = e.target.value;
    setSort(newSort);

    // Update the search params for "sort"
    if (newSort) {
      searchParams.set("sort", newSort);
    } else {
      searchParams.delete("sort");
    }

    // Optionally, you can also reset the page parameter when sort changes
    // searchParams.delete("page");

    // Navigate to the updated URL
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  // If the query string changes externally, update the local state
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sort = params.get("sort") || "";
    setSort(sort);
  }, [location.search]);

  return (
    <div id="nestyachts-sort">
      <select value={sort} onChange={handleSortChange}>
        <option value="">Default Sort</option>
        <option value="price">Price (High-Low)</option>
        <option value="-price">Price (Low-High)</option>
        <option value="pub_year">Year (New-Old)</option>
        <option value="-pub_year">Year (Old-New)</option>
      </select>
    </div>
  );
};

export default PlaneSort;
