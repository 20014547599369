import React, { useState, useEffect } from "react";
import { generalService } from "../../_services/general.service";
import { useMediaQuery, Button, SvgIcon } from "@material-ui/core";
import { useParams } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import ContactAgentForm from "./ContactAgentForm";
import PlaneSkeletonLoader from "./Loaders/PlaneSkeletonLoader";
import { ReactComponent as MySvgIcon } from "../../assets/images/all-photos.svg";
import "./plane.scss";

const ShowAllBtn = ({ images }) => {
  const [openGallery, setOpenGallery] = useState(false);
  const slides = images.map((img) => ({ src: img }));

  return (
    <>
      <Button
        onClick={() => setOpenGallery(true)}
        className="show-all-btn"
        variant="outlined"
        color="default"
        startIcon={<SvgIcon component={MySvgIcon} viewBox="0 0 20 20" />}
        style={{
          textTransform: "none",
          position: "absolute",
          bottom: "8px",
          right: "60px",
        }}
      >
        Show all photos
      </Button>
      <Lightbox
        open={openGallery}
        close={() => setOpenGallery(false)}
        slides={slides}
      />
    </>
  );
};

const Plane = () => {
  const { id } = useParams();
  const isDesktop = useMediaQuery("(min-width:900px)");
  const [planeData, setPlaneData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activePlaneData, setActivePlaneData] = useState("airframe");

  // Fetch plane data by id
  const fetchPlane = (id) => {
    setLoading(true);
    generalService
      .scrapePlane(id)
      .then((response) => {
        setPlaneData(response);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching plane:", err);
        setError("Error fetching plane");
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchPlane(id);
  }, [id]);

  const removeEmpty = (arr) => {
    return arr.filter(
      (item) => item !== null && item !== undefined && item.trim() !== ""
    );
  };

  if (loading) return <PlaneSkeletonLoader />;
  if (error) return <div>{error}</div>;
  if (!planeData) return null;

  // Extract attributes and publicData
  const { attributes } = planeData.data;
  const publicData = attributes.publicData || {};

  const title = publicData.title || attributes.title || "";
  const price = publicData.price || attributes.price;
  const year = publicData.year || attributes.year;
  const model = publicData.category_name || attributes.category_name;
  const condition = publicData.condition || attributes.condition;
  const registration = publicData.reg_num || attributes.reg_num;
  const serial = publicData.serial_num || attributes.serial_num;
  const listingType =
    publicData.aircraft_listing_type || attributes.listingType || "";
  const highlights = publicData.summary_desc || attributes.summary_desc || "";
  let highlightsArr = [];
  if (highlights) {
    highlightsArr = JSON.parse(highlights).blocks.map((block) => block.text);
  }
  const sellerLocation = publicData.aircraft_loc?.selectedPlace?.address || "";
  const aircraftLocation =
    publicData.aircraft_loc?.selectedPlace?.address || "";
  const flightRules = publicData.flight_rules || attributes.flight_rules || "";
  const navigationEquip = publicData.nav_dscr || attributes.nav_dscr || "";
  let navigationEquipArr = [];
  if (navigationEquip) {
    navigationEquipArr = JSON.parse(navigationEquip).blocks.map(
      (block) => block.text
    );
  }
  const commsEquip = publicData.comms_dscr || attributes.comms_dscr || "";
  let commsEquipArr = [];
  if (commsEquip) {
    commsEquipArr = JSON.parse(commsEquip).blocks.map((block) => block.text);
  }
  const additionalEquip =
    publicData.avionics_addl || attributes.avionics_addl || "";
  let additionalEquipArr = [];
  if (additionalEquip) {
    additionalEquipArr = JSON.parse(additionalEquip).blocks.map(
      (block) => block.text
    );
  }
  const seats = publicData.seats || attributes.seats || "";
  const interiorCondition =
    publicData.int_condition || attributes.int_condition || "";
  let interiorConditionArr = [];
  if (interiorCondition) {
    interiorConditionArr = JSON.parse(interiorCondition).blocks.map(
      (block) => block.text
    );
  }
  const interiorYear = publicData.int_year || attributes.int_year || "";
  const interiorScore = publicData.int_score || attributes.int_score || "";
  const exteriorCondition =
    publicData.ext_condition || attributes.ext_condition || "";
  let exteriorConditionArr = [];
  if (exteriorCondition) {
    exteriorConditionArr = JSON.parse(exteriorCondition).blocks.map(
      (block) => block.text
    );
  }
  const paintYear = publicData.paint_year || attributes.paint_year || "";
  const paintScore = publicData.paint_score || attributes.paint_score || "";
  const inspectionStatus =
    publicData.inspect_status || attributes.inspect_status || "";
  let inspectionStatusArr = [];
  if (inspectionStatus) {
    inspectionStatusArr = JSON.parse(inspectionStatus).blocks.map(
      (block) => block.text
    );
  }
  const damageHistory =
    publicData.damage_history || attributes.damage_history || "";
  let damageHistoryArr = [];
  if (damageHistory) {
    damageHistoryArr = JSON.parse(damageHistory).blocks.map(
      (block) => block.text
    );
  }
  const airframeDesc =
    publicData.airframe_desc || attributes.airframe_desc || "";
  let airframeDescArr = [];
  if (airframeDesc) {
    airframeDescArr = JSON.parse(airframeDesc).blocks.map(
      (block) => block.text
    );
  }
  const airframeTime =
    publicData.airframe_time || attributes.airframe_time || "";
  const propulsionDesc =
    publicData.propulsion_desc || attributes.propulsion_desc || "";
  let propulsionDescArr = [];
  if (propulsionDesc) {
    propulsionDescArr = JSON.parse(propulsionDesc).blocks.map(
      (block) => block.text
    );
  }

  // Extract image URLs from included array (filter for images)
  const images = (planeData.included || [])
    .filter((item) => item.type === "image")
    .map((item) => {
      const variants = item.attributes.variants || {};
      // Try to use a high-quality variant first, fall back if not available
      return (
        // variants["listing-card-6x"]?.url ||
        variants["scaled-large"]?.url || variants["square-small"]?.url || ""
      );
    })
    .filter((url) => url);

  const formatPrice = (price) => {
    return (
      "$" +
      price
        .toString()
        .slice(0, -2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    );
  };

  const arrayToList = (array) => {
    return (
      <ul>
        {array.map((item, i) => (
          <li key={i}>{item}</li>
        ))}
      </ul>
    );
  };

  return (
    <>
      <div className="yacht-page" style={{ marginBottom: "32px" }}>
        <section className="property-details-hero-section">
          <div className="property-details-hero-container">
            <div className="property-details-hero-title">
              <h1>{title}</h1>
            </div>
            <div className="property-details-hero-images-wrapper">
              <div className="main-image-wrapper">
                <div
                  className="main-image"
                  style={{ backgroundImage: `url('${images[0]}')` }}
                />
              </div>
              <div className="grid-images-wrapper">
                <div
                  className="grid-image"
                  style={{ backgroundImage: `url('${images[1]}')` }}
                />
                <div
                  className="grid-image"
                  style={{ backgroundImage: `url('${images[2]}')` }}
                />
                <div
                  className="grid-image"
                  style={{ backgroundImage: `url('${images[3]}')` }}
                />
                <div
                  className="grid-image"
                  style={{ backgroundImage: `url('${images[4]}')` }}
                />
                <ShowAllBtn images={images} />
              </div>
            </div>
            {!isDesktop ? (
              <Carousel
                responsive={{
                  mobile: {
                    breakpoint: {
                      max: 900,
                      min: 0,
                    },
                    items: 1,
                  },
                }}
              >
                {images.map((image, index) => (
                  <div
                    key={index}
                    className="property-details-hero-images-carousel"
                  >
                    <div
                      style={{
                        backgroundImage: `url('${image}')`,
                      }}
                    />
                  </div>
                ))}
              </Carousel>
            ) : null}
            <p className="main-price">{formatPrice(price.amount)}</p>
            <p className="location-paragraph">
              <strong>SELLER LOCATION:</strong> {sellerLocation}
            </p>
            <p className="location-paragraph">
              <strong>AIRCRAFT LOCATION:</strong> {aircraftLocation}
            </p>
          </div>
        </section>
        <section className="yacht-overview-section">
          <div className="yacht-overview">
            <div className="yacht-summary-details-section">
              <div className="yacht-details-section p-4">
                <div className="yacht-detail">
                  <div className="yacht-detail-label">MODEL</div>
                  <div>
                    {model} - {condition}
                  </div>
                </div>
                <div className="yacht-detail">
                  <div className="yacht-detail-label">REGISTRATION NUMBER</div>
                  <div>{registration || "N/A"}</div>
                </div>
                <div className="yacht-detail">
                  <div className="yacht-detail-label">SERIAL NUMBER</div>
                  <div>{serial || "N/A"}</div>
                </div>
                <div className="yacht-detail">
                  <div className="yacht-detail-label">
                    AIRCRAFT LISTING TYPE
                  </div>
                  <div>{listingType || "N/A"}</div>
                </div>
              </div>
              <br />
              <h2>Highlights</h2>
              <i>{arrayToList(removeEmpty(highlightsArr))}</i>
              <br />
            </div>
          </div>
        </section>
        <section className="yacht-detail-section">
          <div
            style={{
              backgroundImage: `url(${images[0]})`,
            }}
            className="yacht-detail-container"
          >
            <div className="yacht-detail-data">
              <h3>Details</h3>
              <div className="yacht-detail-filters">
                <div
                  className={`yacht-detail-filter ${
                    activePlaneData === "airframe" &&
                    "yacht-detail-filters-active"
                  }`}
                  onClick={() => setActivePlaneData("airframe")}
                >
                  Airframe & Propulsion
                </div>
                <div
                  className={`yacht-detail-filter ${
                    activePlaneData === "avionics" &&
                    "yacht-detail-filters-active"
                  }`}
                  onClick={() => setActivePlaneData("avionics")}
                >
                  Avionics
                </div>
                <div
                  className={`yacht-detail-filter ${
                    activePlaneData === "interior" &&
                    "yacht-detail-filters-active"
                  }`}
                  onClick={() => setActivePlaneData("interior")}
                >
                  Interior & Exterior
                </div>
                <div
                  className={`yacht-detail-filter ${
                    activePlaneData === "maintenance" &&
                    "yacht-detail-filters-active"
                  }`}
                  onClick={() => setActivePlaneData("maintenance")}
                >
                  Maintenance
                </div>
              </div>
              <div className="yacht-detail-data-content">
                {activePlaneData === "airframe" && (
                  <div>
                    <p className="details-title">Airframe Time</p>
                    {airframeTime} hours
                    <p className="details-title">Airframe Description</p>
                    {arrayToList(removeEmpty(airframeDescArr))}
                    <p className="details-title">Propulsion</p>
                    {arrayToList(removeEmpty(propulsionDescArr))}
                  </div>
                )}
                {activePlaneData === "avionics" && (
                  <div>
                    <p className="details-title">Flight Rules</p>
                    {flightRules}
                    <p className="details-title">Navigation Equiment</p>
                    {arrayToList(removeEmpty(navigationEquipArr))}
                    <p className="details-title">Comms Equiment</p>
                    {arrayToList(removeEmpty(commsEquipArr))}
                    <p className="details-title">Additional Equiment</p>
                    {arrayToList(removeEmpty(additionalEquipArr))}
                  </div>
                )}
                {activePlaneData === "interior" && (
                  <div>
                    <p className="details-title">Number of Seats</p>
                    {seats}
                    <p className="details-title">Interior Condition</p>
                    {arrayToList(removeEmpty(interiorConditionArr))}
                    <p className="details-title">Interior Year</p>
                    {interiorYear}
                    <p className="details-title">Interior Score</p>
                    {interiorScore}
                    <p className="details-title">Exterior Condition</p>
                    {arrayToList(removeEmpty(exteriorConditionArr))}
                    <p className="details-title">Exterior Year</p>
                    {paintYear}
                    <p className="details-title">Exterior Score</p>
                    {paintScore}
                  </div>
                )}
                {activePlaneData === "maintenance" && (
                  <div>
                    <p className="details-title">Inspection Status</p>
                    {arrayToList(removeEmpty(inspectionStatusArr))}
                    <p className="details-title">Damage History</p>
                    {arrayToList(removeEmpty(damageHistoryArr))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="contact-section">
          <div className="contact-section-container">
            <h3>Request Information</h3>
            <p>Our experienced sale brokers are here to answer any questions</p>
            <ContactAgentForm />
          </div>
        </section>
      </div>
    </>
  );
};

export default Plane;
