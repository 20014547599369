import React from "react";
import { Routes, Route } from "react-router-dom";
import HeaderComponent from "../components/HeaderComponent";
import FooterComponent from "../components/FooterComponent";
import HomePage from "../pages/HomePage";
import NotFound from "../pages/NotFound";
import { SearchPage } from "../pages/SearchPage";
import { PropertyDetailPage } from "../pages/PropertyDetailsPage";
import { ManagePropertyPage } from "../pages/PropertyPage/ManagePropertyPage";
import { AddPropertyPage } from "../pages/PropertyPage/AddPropertyPage";
import DashboardPage from "../pages/Dashboard/DashboardPage";
import InvestmentWalletPage from "../pages/Dashboard/InvestmentWalletPage";
import ProfilePage from "../pages/Dashboard/ProfilePage";
import PaymentsPage from "../pages/Dashboard/PaymentsPage";
import OrdersPage from "../pages/Dashboard/Orders";
import TransactionsPage from "../pages/Dashboard/TransactionsPage";
import { VerificationPage } from "../pages/VerificationKycPage";
import TokenWalletPage from "../pages/Dashboard/TokenWalletPage";
import { TermsOfUsePage } from "../pages/TermsOfUse";
import { FrequentlyAskedQuestions } from "../pages/FrequentlyAskedQuestions";
// import { UnderConstruction } from "../pages/UnderConstruction";
import NewHomePage from "../pages/NewHomePage";

import AuthLayout from "./layouts/AuthLayout";
import Welcome from "../pages/Welcome";
import ContactPage from "../pages/ContactPage";
// import YachtList from "../components/Yachts/YachtList";
import YachtList from "../components/Yachts/YachtListNew";
// import Yacht from "../components/Yachts/Yacht";
import Yacht from "../components/Yachts/YachtNew";
import PlaneList from "../components/Planes/PlaneList";
import Plane from "../components/Planes/Plane";

const UserRoute = ({ logopath, pagebackground, logobackground }) => {
  return (
    <Routes>
      <Route
        path="/users/kyc"
        element={
          <AuthLayout kycPage={true}>
            <>
              <HeaderComponent
                logopath={logopath}
                logobackground={logobackground}
                stickyPosition={200}
                noStickyClass="homeNoStickedHeader"
              />
              <VerificationPage />
            </>
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/orders"
        element={
          <AuthLayout>
            <OrdersPage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/tokenwallet"
        element={
          <AuthLayout>
            <TokenWalletPage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/payments"
        element={
          <AuthLayout>
            <PaymentsPage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/transactions"
        element={
          <AuthLayout>
            <TransactionsPage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/properties"
        element={
          <AuthLayout checkWallet={true}>
            <ManagePropertyPage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/properties/edit/:id"
        element={
          <AuthLayout>
            <AddPropertyPage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/properties/add"
        element={
          <AuthLayout>
            <AddPropertyPage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/wallet"
        element={
          <AuthLayout>
            <InvestmentWalletPage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard/profile"
        element={
          <AuthLayout>
            <ProfilePage />
          </AuthLayout>
        }
      />
      <Route
        path="/dashboard"
        element={
          <AuthLayout>
            <DashboardPage />
          </AuthLayout>
        }
      />
      <Route
        path="/property/view/:id"
        element={
          <>
            <HeaderComponent
              logopath={logopath}
              logobackground={logobackground}
            />
            <PropertyDetailPage />
          </>
        }
      />
      <Route
        path="/marketplace"
        element={
          <>
            <div className="fixed">
              <HeaderComponent
                logopath={logopath}
                logobackground={logobackground}
              />
            </div>
            <SearchPage />
          </>
        }
      />
      <Route
        path="/terms-of-use"
        element={
          <>
            <div className="fixed">
              <HeaderComponent
                logopath={logopath}
                logobackground={logobackground}
              />
            </div>
            <TermsOfUsePage />
            <FooterComponent />
          </>
        }
      />
      <Route
        path="/faq"
        element={
          <>
            <div className="fixed">
              <HeaderComponent
                logopath={logopath}
                logobackground={logobackground}
              />
            </div>
            <FrequentlyAskedQuestions />
            <FooterComponent />
          </>
        }
      />
      <Route
        path="/contact-us"
        element={
          <>
            <div className="fixed">
              <HeaderComponent
                logopath={logopath}
                logobackground={logobackground}
              />
            </div>
            <ContactPage />
            <FooterComponent />
          </>
        }
      />
      <Route
        path="/contact-us-2"
        element={
          <>
            <div className="fixed">
              <HeaderComponent
                logopath={logopath}
                logobackground={logobackground}
              />
            </div>
            <ContactPage />
            <FooterComponent />
          </>
        }
      />
      <Route
        path="/yachts-for-sale"
        element={
          <>
            <div className="fixed">
              <HeaderComponent
                logopath={logopath}
                logobackground={logobackground}
              />
            </div>
            <YachtList />
            <FooterComponent />
          </>
        }
      />
      <Route
        path="/yacht/:id"
        element={
          <>
            <div className="fixed">
              <HeaderComponent
                logopath={logopath}
                logobackground={logobackground}
              />
            </div>
            <Yacht />
            <FooterComponent />
          </>
        }
      />
      <Route
        path="/planes-for-sale"
        element={
          <>
            <div className="fixed">
              <HeaderComponent
                logopath={logopath}
                logobackground={logobackground}
              />
            </div>
            <PlaneList />
            <FooterComponent />
          </>
        }
      />
      <Route
        path="/plane/:id"
        element={
          <>
            <div className="fixed">
              <HeaderComponent
                logopath={logopath}
                logobackground={logobackground}
              />
            </div>
            <Plane />
            <FooterComponent />
          </>
        }
      />
      <Route
        path="/get-started/:type"
        element={
          <>
            <div className="fixed">
              <HeaderComponent
                logopath={logopath}
                logobackground={logobackground}
              />
            </div>
            <Welcome />
            <FooterComponent />
          </>
        }
      />
      {/* <Route exact path="/" element={<UnderConstruction />} /> */}
      <Route
        exact
        path="/"
        element={
          <>
            <HeaderComponent
              logopath={logopath}
              logobackground={logobackground}
              stickyPosition={200}
              noStickyClass="homeNoStickedHeader"
            />
            <NewHomePage />
            {/* <HomePage background={pagebackground} /> */}
            <FooterComponent />
          </>
        }
      />
      <Route
        path="*"
        element={
          <>
            <HeaderComponent
              logopath={logopath}
              logobackground={logobackground}
              stickyPosition={200}
              noStickyClass="homeNoStickedHeader"
            />
            <NotFound />
            <FooterComponent />
          </>
        }
      />
    </Routes>
  );
};

export default UserRoute;
export { UserRoute };
