import { Box, Chip } from "@material-ui/core";
import { numberToRegionName } from "../Yachts/yacht-types";

export const calcuteDividendYield = (
  distribution_surplus,
  financing_volume
) => {
  distribution_surplus = Number(distribution_surplus);
  financing_volume = Number(financing_volume);
  if (!isNaN(distribution_surplus / financing_volume)) {
    return (distribution_surplus / financing_volume).toFixed(2);
  }
  return 0;
};

export const formatCurrencyPrice = (propertyPrice, currencyRate) => {
  if (
    currencyRate !== undefined &&
    currencyRate !== null &&
    !isNaN(currencyRate)
  ) {
    currencyRate *= propertyPrice;
    const parts = currencyRate.toString().split(".");
    parts[1] = parseFloat(`0.${parts[1]}`).toFixed(2).split(".")[1];
    if (parts.length > 1) {
      currencyRate =
        parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
        (parts[1] ? "," + parts[1] : "");
    }
    return currencyRate;
  }
  return "-";
};

export const findSectionByKey = (array, key) => {
  const result = array.find((item) => item.section_key === key);
  return result || null;
};

export const camelCaseToTitleCase = (str) => {
  // Function to check if the string is in camelCase
  function isCamelCase(s) {
    // Check if the string starts with a lowercase letter and contains at least one uppercase letter
    return /^[a-z][a-zA-Z]*$/.test(s) && /[A-Z]/.test(s);
  }

  if (isCamelCase(str)) {
    // String is in camelCase, proceed with conversion
    // Insert a space before all uppercase letters
    const result = str.replace(/([A-Z])/g, " $1").trim();

    // Capitalize the first letter of each word
    const titleCase = result.replace(/\b\w/g, (match) => match.toUpperCase());

    return titleCase;
  } else if (/^[a-z]/.test(str)) {
    // String is not camelCase but starts with a lowercase letter
    // Capitalize the first letter
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    // String is not camelCase and does not start with a lowercase letter, return it as is
    return str;
  }
};

export const removePageParam = (url) => {
  return url.replace(/([?&])page=\d+(&?)/, function (match, p1, p2) {
    return p2 ? p1 : "";
  });
};

export const generateFilterList = (filterParams) => {
  const {
    min_year,
    max_year,
    geo,
    min_length,
    max_length,
    metric,
    min_price,
    max_price,
    currency,
    types,
    search,
  } = filterParams;

  const chips = [];

  // Price
  if (min_price && max_price && currency) {
    chips.push(
      <Chip
        key="price"
        label={`Price: ${min_price} - ${max_price} (${currency})`}
        style={{ marginTop: "4px", marginRight: "8px" }}
      />
    );
  }

  // Length
  if (min_length && max_length && metric) {
    chips.push(
      <Chip
        key="length"
        label={`Length: ${min_length} - ${max_length} (${metric})`}
        style={{ marginTop: "4px", marginRight: "8px" }}
      />
    );
  }

  // Year
  if (min_year && max_year) {
    chips.push(
      <Chip
        key="year"
        label={`Year: ${min_year} - ${max_year}`}
        style={{ marginTop: "4px", marginRight: "8px" }}
      />
    );
  }

  // Region
  if (geo) {
    const geoNumbers = geo.split("*").filter(Boolean).map(Number);
    const regionNames = geoNumbers
      .map((number) => numberToRegionName[number])
      .filter(Boolean);

    if (regionNames.length > 0) {
      chips.push(
        <Chip
          key="region"
          label={`Region: ${regionNames.join(", ")}`}
          style={{ marginTop: "4px", marginRight: "8px" }}
        />
      );
    }
  }

  // Boat Type
  if (types) {
    const capitalizedType = types.charAt(0).toUpperCase() + types.slice(1);
    chips.push(
      <Chip
        key="boat-type"
        label={`Boat Type: ${capitalizedType}`}
        style={{ marginTop: "4px", marginRight: "8px" }}
      />
    );
  }

  // Search
  if (search) {
    chips.push(
      <Chip
        key="search"
        label={`Search: ${search}`}
        style={{ marginTop: "4px", marginRight: "8px" }}
      />
    );
  }

  // Return the array of chips
  return (
    <Box display="flex" flexWrap="wrap" alignItems="center">
      {chips}
    </Box>
  );
};
