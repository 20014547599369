import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

const CategoryFilter = ({ params, categories }) => {
  const [selectedCategories, setSelectedCategories] = useState({});

  // Initialize selectedCategories from categories prop and URL query parameter "pub_category"
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const pubCategoryStr = queryParams.get("pub_category") || "";
    const pubCategoryValues = pubCategoryStr
      .split(",")
      .map((v) => v.trim())
      .filter((v) => v !== "");

    const initialState = categories.reduce((acc, category) => {
      const initialItems = category.items.map((item) => ({
        ...item,
        // Use item.value (converted to string) for comparison
        selected: pubCategoryValues.includes(String(item.value)),
      }));
      const groupSelected = initialItems.every((i) => i.selected);
      acc[category.label] = {
        selected: groupSelected,
        items: initialItems,
      };
      return acc;
    }, {});
    setSelectedCategories(initialState);
  }, [categories]);

  const handleParentCheckboxChange = (groupLabel) => {
    setSelectedCategories((prev) => {
      const group = prev[groupLabel];
      const newSelected = !group.selected;
      const updatedGroup = {
        ...group,
        selected: newSelected,
        items: group.items.map((item) => ({
          ...item,
          selected: newSelected,
        })),
      };
      return { ...prev, [groupLabel]: updatedGroup };
    });
  };

  const handleChildCheckboxChange = (groupLabel, itemValue) => {
    setSelectedCategories((prev) => {
      const group = prev[groupLabel];
      const updatedItems = group.items.map((item) =>
        item.value === itemValue ? { ...item, selected: !item.selected } : item
      );
      const allSelected = updatedItems.every((item) => item.selected);
      return {
        ...prev,
        [groupLabel]: {
          selected: allSelected,
          items: updatedItems,
        },
      };
    });
  };

  const buildQueryParams = () => {
    const selectedValues = Object.values(selectedCategories)
      .flatMap((group) =>
        group.items.filter((item) => item.selected).map((item) => item.value)
      )
      .join(",");
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.delete("page"); // reset page
    if (selectedValues) {
      queryParams.set("pub_category", selectedValues);
    } else {
      queryParams.delete("pub_category");
    }
    return queryParams.toString();
  };

  const handleApply = () => {
    const currentUrl = window.location.href;
    const baseUrl = currentUrl.split("?")[0];
    const newUrl = `${baseUrl}?${buildQueryParams()}`;
    window.location.href = newUrl;
  };

  const handleReset = () => {
    const resetState = categories.reduce((acc, category) => {
      const items = category.items.map((item) => ({
        ...item,
        selected: false,
      }));
      acc[category.label] = { selected: false, items };
      return acc;
    }, {});
    setSelectedCategories(resetState);
  };

  return (
    <FormControl className="yacht-form-control-wrapper planes-form-control-wrapper">
      <FormGroup>
        {categories.map((category) => (
          <div key={category.label} style={{ marginBottom: "10px" }}>
            <FormControlLabel
              className="planes-category-checkbox-group-label"
              control={
                <Checkbox
                className="planes-category-checkbox-group"
                  checked={
                    selectedCategories[category.label]?.selected || false
                  }
                  onChange={() => handleParentCheckboxChange(category.label)}
                />
              }
              label={category.label}
            />
            <FormGroup style={{ marginLeft: "30px" }}>
              {category.items.map((item) => (
                <FormControlLabel
                  key={item.value}
                  control={
                    <Checkbox
                      className="planes-category-checkbox"
                      checked={
                        selectedCategories[category.label]?.items.find(
                          (i) => i.value === item.value
                        )?.selected || false
                      }
                      onChange={() =>
                        handleChildCheckboxChange(category.label, item.value)
                      }
                    />
                  }
                  label={item.label}
                />
              ))}
            </FormGroup>
          </div>
        ))}
      </FormGroup>
      <div className="yacht-filter-form-btn-wrapper plane-filter-form-btn-wrapper">
        <Button onClick={handleReset} className="btn-delete" variant="outlined">
          Reset
        </Button>
        <Button
          onClick={handleApply}
          className="btn-save"
          variant="contained"
          color="primary"
          style={{ marginLeft: "8px" }}
        >
          Apply
        </Button>
      </div>
    </FormControl>
  );
};

export default CategoryFilter;
