import React, { useEffect, useState } from "react";
import { Button, FormControl, TextField } from "@material-ui/core";
import "../styles.scss";

const YearFilter = ({ params }) => {
  const [minYear, setMinYear] = useState("");
  const [maxYear, setMaxYear] = useState("");

  useEffect(() => {
    if (params.pub_year) {
      const [min, max] = params.pub_year.split(",").map((num) => num.trim());
      setMinYear(min || "");
      setMaxYear(max || "");
    }
  }, [params]);

  const handleApply = () => {
    const currentUrl = window.location.href;
    const baseUrl = currentUrl.split("?")[0];
    const queryParams = new URLSearchParams(window.location.search);

    // Remove existing page parameter
    queryParams.delete("page");
    // Remove year parameter if empty values
    if (!minYear && !maxYear) {
      queryParams.delete("pub_year");
    } else {
      // Set the new pub_year parameter
      const pubYear = [minYear, maxYear].join(",");
      queryParams.set("pub_year", pubYear);
    }

    const newUrl = `${baseUrl}?${queryParams.toString()}`;
    window.location.href = newUrl;
  };

  const handleReset = () => {
    setMinYear("");
    setMaxYear("");
  };

  return (
    <FormControl className="yacht-form-control-wrapper">
      <p>Year Range</p>
      <div className="yacht-filter-form-fields-wrapper">
        <TextField
          className="yacht-filter-fields"
          label="Min Year"
          value={minYear}
          onChange={(e) => setMinYear(e.target.value)}
          type="number"
          variant="outlined"
          size="small"
          name="year-min"
        />
        <TextField
          className="yacht-filter-fields"
          label="Max Year"
          value={maxYear}
          onChange={(e) => setMaxYear(e.target.value)}
          type="number"
          variant="outlined"
          size="small"
          name="year-max"
        />
      </div>
      <div className="yacht-filter-form-btn-wrapper">
        <Button onClick={handleReset} className="btn-delete">
          Reset
        </Button>
        <Button onClick={handleApply} className="btn-save">
          Apply
        </Button>
      </div>
    </FormControl>
  );
};

export default YearFilter;
