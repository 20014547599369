import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    maxWidth: 510,
  },
  input: {
    marginLeft: theme.spacing(1.5),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const YachtSearchField = () => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const searchParam = queryParams.get("search") || "";
    setSearchTerm(searchParam);
  }, []);

  const triggerSearch = () => {
    const currentUrl = window.location.href;
    const baseUrl = currentUrl.split("?")[0];
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.delete("page");
    queryParams.set("search", searchTerm);
    const newUrl = `${baseUrl}?${queryParams.toString()}`;
    window.location.href = newUrl;
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      triggerSearch();
    }
  };

  return (
    <Paper component="div" className={classes.root}>
      <InputBase
        className={classes.input}
        value={searchTerm}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Search by Yacht Name, Builder"
        inputProps={{ "aria-label": "search yachts" }}
      />
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton
        onClick={triggerSearch}
        color="primary"
        className={classes.iconButton}
        aria-label="search"
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

export default YachtSearchField;
