import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  useMediaQuery,
  SvgIcon,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./styles.scss";
import { ReactComponent as MySvgIcon } from "../../assets/images/all-photos.svg";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import ContactAgentForm from "./ContactAgentForm";
import YachtSkeletonLoader from "./Loaders/YachtSkeletonLoader";
import { generalService } from "../../_services/general.service";
import { yachtsConstants } from "../../_constants";
import Carousel from "react-multi-carousel";

const ShowAllBtn = ({ images }) => {
  const [openGallery, setOpenGallery] = useState(false);
  const slides =
    images && images.length > 0
      ? images.map((img) => ({
          src: img.full_image_path,
        }))
      : [];
  return (
    <>
      <Button
        onClick={() => setOpenGallery(true)}
        style={{
          textTransform: "none",
          position: "absolute",
          bottom: "8px",
          right: "60px",
        }}
        variant="outlined"
        color="default"
        startIcon={<SvgIcon component={MySvgIcon} viewBox="0 0 20 20" />}
      >
        Show all photos
      </Button>
      <Lightbox
        open={openGallery}
        close={() => setOpenGallery(false)}
        slides={slides}
      />
    </>
  );
};

const Yacht = () => {
  const { id } = useParams();
  const isDesktop = useMediaQuery("(min-width:900px)");

  // yachtData holds fetched yacht fields plus an "activeSection" for the Details tabs.
  const [yachtData, setYachtData] = useState({
    activeSection: "characteristics",
  });
  // gallery holds the images from yacht_images
  const [gallery, setGallery] = useState({ pics: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch yacht data from the API
  const fetchYachtData = async (id) => {
    try {
      setLoading(true);
      const response = await generalService.fetchYacht(id);
      setYachtData({
        ...response,
        activeSection: "characteristics",
      });
      if (response.yacht_images && response.yacht_images.length > 0) {
        setGallery({
          pics: response.yacht_images,
        });
      } else {
        setGallery({ pics: [] });
      }
      setLoading(false);
    } catch (err) {
      console.error("Error fetching yacht:", err);
      setError("Error fetching yacht");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchYachtData(id);
    }
  }, [id]);

  if (loading) return <YachtSkeletonLoader />;
  if (error) return <div>{error}</div>;
  if (!yachtData) return null;

  const getImage = (index) => {
    const placeholderImg = yachtsConstants.PLACEHOLDER_IMAGE;
    if (
      gallery &&
      gallery.pics &&
      gallery.pics[index] &&
      gallery.pics[index].image_path
    ) {
      const img = gallery.pics[index].image_path;
      return img.includes("cdn.yachtbroker.org") ? placeholderImg : img;
    }
    return placeholderImg;
  };

  // --------------------------
  // OVERVIEW SECTION (HTML structure preserved)
  // --------------------------
  const overviewSection = (
    <section className="yacht-overview-section">
      <div className="yacht-overview">
        <div className="yacht-summary-details-section">
          <div className="yacht-details-section p-4">
            <div className="yacht-detail">
              <div className="yacht-detail-label">LENGTH</div>
              <div>
                {yachtData.length_display_feet || "N/A"}{" "}
                {yachtData.length_display_meters && (
                  <small>({yachtData.length_display_meters})</small>
                )}
              </div>
            </div>
            <div className="yacht-detail">
              <div className="yacht-detail-label">BUILDER</div>
              <div>{yachtData.vessel_manufacturer || "N/A"}</div>
            </div>
            <div className="yacht-detail">
              <div className="yacht-detail-label">BUILT/REFIT</div>
              <div>
                {yachtData.year || "N/A"}
                {yachtData.refit_year ? "/" + yachtData.refit_year : ""}
              </div>
            </div>
            {yachtData.sale_in_US_waters === false && (
              <div className="yacht-detail">
                <div className="sale-US-waters">
                  Not for sale or charter to U.S. residents while in U.S.
                  waters.
                </div>
              </div>
            )}
          </div>
          <br />
          <i
            dangerouslySetInnerHTML={{
              __html: yachtData.description.replace(/\<br\/\>/g, "") || "",
            }}
          />
          <br />
        </div>
        <div className="yacht-numbers-section">
          {yachtData.cabin_count ? (
            <div className="yacht-number-detail">
              <div className="yacht-number-detail-icon">
                <i className="fa fa-bed"></i>
              </div>
              <div>{yachtData.cabin_count || 0} CABINS</div>
            </div>
          ) : null}
          {yachtData.sleep_count ? (
            <div className="yacht-number-detail">
              <div className="yacht-number-detail-icon">
                <i className="fa fa-user"></i>
              </div>
              <div>{yachtData.sleep_count || 0} GUESTS</div>
            </div>
          ) : null}
          {yachtData.crew_sleep_count ? (
            <div className="yacht-number-detail">
              <div className="yacht-number-detail-icon">
                <i className="fa fa-users"></i>
              </div>
              <div>{yachtData.crew_sleep_count || 0} CREW</div>
            </div>
          ) : null}
          <div className="yacht-number-detail">
            <div className="yacht-number-detail-icon">ASKING PRICE</div>
            <div
              dangerouslySetInnerHTML={{
                __html: yachtData.price || "N/A",
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );

  // --------------------------
  // DETAILS SECTION WITH TABS
  // --------------------------
  const renderTabs = () => {
    const tabs = [
      { key: "characteristics", label: "characteristics" },
      { key: "dimensions", label: "dimensions" },
      { key: "speed", label: "speed/weight" },
      { key: "desk", label: "hull/deck" },
    ];
    return (
      <div className="yacht-detail-filters">
        {tabs.map((tab) => (
          <div
            key={tab.key}
            className={`yacht-detail-filter ${
              yachtData.activeSection === tab.key
                ? "yacht-detail-filters-active"
                : "false"
            }`}
            onClick={() =>
              setYachtData({ ...yachtData, activeSection: tab.key })
            }
          >
            {tab.label}
          </div>
        ))}
      </div>
    );
  };

  const renderTabContent = () => {
    switch (yachtData.activeSection) {
      case "characteristics":
        return (
          <div>
            <div className="yacht-full-detail">
              <div className="yacht-full-detail-label">LENGTH</div>
              <div>
                {yachtData.length_display_feet || "N/A"}{" "}
                {yachtData.length_display_meters && (
                  <small>({yachtData.length_display_meters})</small>
                )}
              </div>
            </div>
            <div className="yacht-full-detail">
              <div className="yacht-full-detail-label">BUILDER</div>
              <div>{yachtData.vessel_manufacturer || "N/A"}</div>
            </div>
            <div className="yacht-full-detail">
              <div className="yacht-full-detail-label">YEAR BUILT</div>
              <div>{yachtData.year || "N/A"}</div>
            </div>
            {yachtData.vessel_model && (
              <div className="yacht-full-detail">
                <div className="yacht-full-detail-label">MODEL</div>
                <div>{yachtData.vessel_model}</div>
              </div>
            )}
            {yachtData.vessel_designer && (
              <div className="yacht-full-detail">
                <div className="yacht-full-detail-label">DESIGNER</div>
                <div>{yachtData.vessel_designer}</div>
              </div>
            )}
          </div>
        );
      case "dimensions":
        return (
          <div>
            <div className="yacht-full-detail">
              <div className="yacht-full-detail-label">LOA</div>
              <div>{yachtData.loa_display || "N/A"}</div>
            </div>
            <div className="yacht-full-detail">
              <div className="yacht-full-detail-label">BEAM</div>
              <div>{yachtData.beam_display || "N/A"}</div>
            </div>
            <div className="yacht-full-detail">
              <div className="yacht-full-detail-label">MAX DRAFT</div>
              <div>{yachtData.maximum_draft_display || "N/A"}</div>
            </div>
            <div className="yacht-full-detail">
              <div className="yacht-full-detail-label">FUEL TANK CAPACITY</div>
              <div>
                {yachtData.fuel_tank_capacity_gallons
                  ? parseFloat(yachtData.fuel_tank_capacity_gallons).toFixed(
                      2
                    ) + " GAL"
                  : "N/A"}{" "}
                {yachtData.fuel_tank_capacity_liters
                  ? "(" +
                    parseFloat(yachtData.fuel_tank_capacity_liters).toFixed(2) +
                    " L)"
                  : ""}
              </div>
            </div>
          </div>
        );
      case "speed":
        return (
          <div>
            <div className="yacht-full-detail">
              <div className="yacht-full-detail-label">FUEL TYPE</div>
              <div>{yachtData.vessel_fuel_type || "N/A"}</div>
            </div>
          </div>
        );
      case "desk":
        return (
          <div>
            <div className="yacht-full-detail">
              <div className="yacht-full-detail-label">HULL MATERIAL</div>
              <div>{yachtData.vessel_hull_material || "N/A"}</div>
            </div>
            {yachtData.vessel_hull_finish && (
              <div className="yacht-full-detail">
                <div className="yacht-full-detail-label">HULL FINISH</div>
                <div>{yachtData.vessel_hull_finish}</div>
              </div>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  // FULL SPECIFICATIONS SECTION: Render an accordion for each key figure (if available)
  const renderFullSpecifications = () => {
    if (
      !yachtData.yacht_key_figures ||
      yachtData.yacht_key_figures.length === 0
    ) {
      return null;
    }
    return (
      <section className="yacht-full-specifications-section">
        <div className="full-specifications-container">
          <h3>Full Specifications</h3>
          {yachtData.yacht_key_figures.map((figure) => (
            <Accordion key={figure.id}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div className="full-specifications-key">
                  {figure.yacht_key}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  className="full-specifications-value"
                  dangerouslySetInnerHTML={{
                    __html: figure.yacht_value.replace(/\<br\>/g, ""),
                  }}
                />
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </section>
    );
  };

  return (
    <div className="yacht-page" style={{ marginBottom: "32px" }}>
      {/* HERO SECTION */}
      <section className="property-details-hero-section">
        <div className="property-details-hero-container">
          <div className="property-details-hero-title">
            <h1>
              {yachtData.kw_page_title ||
                yachtData.title ||
                yachtData.vessel_name ||
                "No Name"}
            </h1>
          </div>
          <div className="property-details-hero-images-wrapper">
            <div className="main-image-wrapper">
              <div
                className="main-image"
                style={{ backgroundImage: `url('${getImage(1)}')` }}
              />
            </div>
            <div className="grid-images-wrapper">
              <div
                className="grid-image"
                style={{ backgroundImage: `url('${getImage(2)}')` }}
              />
              <div
                className="grid-image"
                style={{ backgroundImage: `url('${getImage(3)}')` }}
              />
              <div
                className="grid-image"
                style={{ backgroundImage: `url('${getImage(4)}')` }}
              />
              <div
                className="grid-image"
                style={{ backgroundImage: `url('${getImage(5)}')` }}
              />
              <ShowAllBtn images={gallery.pics || []} />
            </div>
          </div>
          {!isDesktop && gallery.pics && gallery.pics.length > 0 ? (
            <Carousel
              responsive={{
                mobile: {
                  breakpoint: {
                    max: 900,
                    min: 0,
                  },
                  items: 1,
                },
              }}
            >
              {gallery.pics.map((image, index) => (
                <div
                  key={index}
                  className="property-details-hero-images-carousel"
                >
                  <div
                    style={{
                      backgroundImage: `url('${getImage(index)}')`,
                    }}
                  />
                </div>
              ))}
            </Carousel>
          ) : null}
          <p
            className="main-price"
            dangerouslySetInnerHTML={{
              __html: yachtData.price || "N/A",
            }}
          />
        </div>
      </section>

      {/* OVERVIEW SECTION */}
      {overviewSection}

      {/* DETAILS SECTION WITH TABS */}
      <section className="yacht-detail-section">
        <div
          className="yacht-detail-container"
          style={{
            backgroundImage: `url(${getImage(1)})`,
          }}
        >
          <div className="yacht-detail-data">
            <h3>Details</h3>
            {renderTabs()}
            <div className="yacht-detail-data-content">
              {renderTabContent()}
            </div>
          </div>
        </div>
      </section>

      {/* FULL SPECIFICATIONS SECTION USING ACCORDION */}
      {renderFullSpecifications()}

      {/* CONTACT SECTION */}
      <section className="contact-section">
        <div className="contact-section-container">
          <h3>Request Information</h3>
          <p>Our experienced sale brokers are here to answer any questions</p>
          <ContactAgentForm
            yachtId={id}
            title={
              yachtData.kw_page_title ||
              yachtData.title ||
              yachtData.vessel_name ||
              "No Name"
            }
          />
        </div>
      </section>
    </div>
  );
};

export default Yacht;
