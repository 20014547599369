import { authHeader } from "../_helpers";
import { initialState } from "../_reducers/general.reducer";

export const generalService = {
  get_locations,
  getProperty,
  getPartner,
  getPartnerById,
  addPartner,
  updatePartnerById,
  deletePartnerById,
  get_all_properties,
  addProperty,
  getPropertyById,
  updateProperty,
  updatePropertyValues,
  purchaseProperty,
  transferProperty,
  getProperties,
  deletePropertyById,
  filterProperty,
  verifyPaymentDetails,
  getCryptoList,
  getCurrencyRates,
  getFinancialInstruments,
  getDividendDistributions,
  getAvailableLocations,
  getTotalPropertiesCount,
  getAdminStatistic,
  hashDocuments,
  getPropertyPricing,
  getAllPropertyActiveLocations,
  updatePropertySortPictures,
  uploadPropertyImage,
  scrapeYachts,
  scrapeYacht,
  fetchYachts,
  fetchYacht,
  scrapePlanes,
  scrapePlane,
};

function get_locations() {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/locations`,
    requestOptions
  ).then(handleResponse);
}

function getAllPropertyActiveLocations(userLocations = false) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  const getUserLocations = (sign = "") => {
    return (userLocations && `${sign}user_locations=true`) || "";
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/locations-filter${getUserLocations("?")}`,
    requestOptions
  ).then(handleResponse);
}

async function getProperty(propertyId) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  var initialStateParams = initialState();

  return fetch(
    `${process.env.REACT_APP_API_URL}/properties/${propertyId}?lng=${initialStateParams.languageCode}`,
    requestOptions
  ).then(handleResponse);
}

function getTotalPropertiesCount() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/properties/count`,
    requestOptions
  ).then(handleResponse);
}

async function get_all_properties(page = 0, pageSize = 10, status, filter) {
  // add other filters like promoted
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };

  const getStatus = () => {
    let query = "";
    if (status) {
      query = `&status=${status}`;
    }

    return query;
  };

  const getFilters = () => {
    let query = "";

    if (filter) {
      const {
        addressCountries: countries,
        addressCities: cities,
        propertyTitle: title,
        promoted,
        partner_id,
      } = filter;

      if (countries.length) {
        query += `&countries=${JSON.stringify(countries)}`;
      }
      if (cities.length) {
        query += `&cities=${JSON.stringify(cities)}`;
      }
      if (title.length) {
        query += `&title=${JSON.stringify(title)}`;
      }
      // filter promoted
      if (promoted) {
        query += `&promoted=${JSON.stringify(promoted)}`;
      }
      // filter by partner
      if (partner_id) {
        query += `&partner_id=${JSON.stringify(partner_id)}`;
      }
    }

    return query;
  };

  var initialStateParams = initialState();
  return fetch(
    `${process.env.REACT_APP_API_URL}/properties?limit=${pageSize}&page=${page}
    &lng=${initialStateParams.languageCode}${getStatus()}${getFilters()}`,
    requestOptions
  ).then(handleResponse);
}

// PARTNER SERVICES
function addPartner(data) {
  var formData = new FormData();
  if (data && data.name_en) {
    Object.keys(data).forEach(function (key) {
      if (key !== "partner_logoPreview") formData.append(key, data[key]);
    });
  }

  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
    body: formData,
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/partners`,
    requestOptions
  ).then(handleResponse);
}

function getPropertyPricing(type) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/properties/pricing/${type}`,
    requestOptions
  ).then(handleResponse);
}

async function getPartner(
  page = 0,
  pageSize = 10,
  showAll = false,
  search = ""
) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };
  const initialStateParams = initialState();

  let url = `${process.env.REACT_APP_API_URL}/partners?languageCode=${initialStateParams.languageCode}&page=${page}&limit=${pageSize}&all=${showAll}`;
  if (search) {
    url += `&search=${encodeURIComponent(search)}`;
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function getPartnerById(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/partners/` + id,
    requestOptions
  ).then(handleResponse);
}

function updatePartnerById(data) {
  var formData = new FormData();
  if (data && data.id) {
    Object.keys(data).forEach(function (key) {
      if (key !== "partner_logoPreview") formData.append(key, data[key]);
    });
  }
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader() },
    body: formData,
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/partners`,
    requestOptions
  ).then(handleResponse);
}

function deletePartnerById(id) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader() },
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/partners/${id}`,
    requestOptions
  ).then(handleResponse);
}

// PARTNER SERVICES

async function getProperties(dataLimit, page, isPromoted) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  var initialStateParams = initialState();

  let url = `${process.env.REACT_APP_API_URL}/properties/marketplace?limit=${dataLimit}&page=${page}&lng=${initialStateParams.languageCode}`;
  if (isPromoted) {
    url += `&promoted=${isPromoted}`;
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function deletePropertyById(id) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/properties/${id}`,
    requestOptions
  ).then(handleResponse);
}

function getPropertyById(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/properties/${id}`,
    requestOptions
  ).then(handleResponse);
}

function updatePropertySortPictures(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ images: data }),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/properties/sort-pictures`,
    requestOptions
  ).then(handleResponse);
}

function updateProperty(data) {
  const id = data.property_id;
  var formData = new FormData();
  if (data && Object.keys(data).length > 0) {
    for (var key in data) {
      if (!Array.isArray(data[key])) {
        if (key !== "floor_plan_image_preview") {
          if (
            key === "floor_plan_image" ||
            key === "macro_location_image" ||
            key === "micro_location_image"
          ) {
            if (data[key]) formData.append(key, data[key], data[key].name);
          } else {
            if (key === "property_location") {
              var property_location = JSON.stringify(data[key]);
              formData.append(key, property_location);
            } else {
              formData.append(key, data[key]);
            }
          }
        }
      } else {
        if (key === "key_figures") {
          var key_figures = JSON.stringify(data[key]);
          formData.append(key, key_figures);
        } else if (key === "sections") {
          var sections = JSON.stringify(data[key]);
          formData.append(key, sections);
        } else if (key === "partners") {
          var partners = JSON.stringify(data[key]);
          formData.append(key, partners);
        } else if (key === "property_floor_plans") {
          var property_floor_plans = data[key];
          var property_floor_plans_rest = property_floor_plans.map((pp) => {
            if (pp.floor_plan_image) {
              formData.append(
                "floor_plan_images",
                pp.floor_plan_image,
                pp.floor_plan_image.name
              );
            }
            const rest = { ...pp };
            delete rest.floor_plan_image;
            delete rest.floor_plan_image_preview;
            return rest;
          });
          formData.append(
            "floor_plans_rest",
            JSON.stringify(property_floor_plans_rest)
          );
        } else if (key === "property_picture") {
          var property_pictures = data[key];
          var property_pictures_rest = {};
          for (var pictureItem_key in property_pictures) {
            var picture_item = property_pictures[pictureItem_key];
            // if (picture_item.id && !picture_item.isDelete) {
            //     continue;
            // }
            property_pictures_rest[pictureItem_key] = {};
            for (var item_key in picture_item) {
              if (
                item_key === "property_picture_preview" ||
                item_key === "tab_value"
              ) {
                continue;
              }
              if (item_key === "property_images" && picture_item[item_key]) {
                formData.append(
                  item_key,
                  picture_item[item_key],
                  picture_item[item_key].name
                );
              } else {
                property_pictures_rest[pictureItem_key][item_key] =
                  picture_item[item_key];
              }
            }
          }
          formData.append(
            "property_pictures_rest",
            JSON.stringify(property_pictures_rest)
          );
        } else if (key === "property_documents") {
          var property_documents = data[key];
          var property_documents_rest = [];
          for (var doc_item_key in property_documents) {
            var doc_item = property_documents[doc_item_key];
            property_documents_rest[doc_item_key] = {};
            for (var sub_item_key in doc_item) {
              if (
                sub_item_key !== "property_doc_filename" &&
                sub_item_key !== "tab_value"
              ) {
                if (
                  sub_item_key === "property_doc_file" &&
                  doc_item[sub_item_key]
                ) {
                  formData.append(
                    "property_documents",
                    doc_item[sub_item_key],
                    doc_item[sub_item_key].name
                  );
                } else {
                  property_documents_rest[doc_item_key][sub_item_key] =
                    doc_item[sub_item_key];
                }
              }
            }
          }
          formData.append(
            "property_documents_rest",
            JSON.stringify(property_documents_rest)
          );
        }
      }
    }
  }

  formData.delete("macro_location_image_preview");
  formData.delete("micro_location_image_preview");

  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader() },
    body: formData,
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/properties/${id}`,
    requestOptions
  ).then(handleResponse);
}

function updatePropertyValues(data) {
  const id = data.property_id;
  var formData = new FormData();
  if (data && Object.keys(data).length > 0) {
    for (var key in data) {
      if (!Array.isArray(data[key])) {
        if (key !== "floor_plan_image_preview") {
          if (
            key === "floor_plan_image" ||
            key === "macro_location_image" ||
            key === "micro_location_image"
          ) {
            if (data[key]) formData.append(key, data[key], data[key].name);
          } else {
            if (key === "property_location") {
              var property_location = JSON.stringify(data[key]);
              formData.append(key, property_location);
            } else {
              formData.append(key, data[key]);
            }
          }
        }
      } else {
        if (key === "key_figures") {
          var key_figures = JSON.stringify(data[key]);
          formData.append(key, key_figures);
        } else if (key === "sections") {
          var sections = JSON.stringify(data[key]);
          formData.append(key, sections);
        } else if (key === "partners") {
          var partners = JSON.stringify(data[key]);
          formData.append(key, partners);
        } else if (key === "property_picture") {
          var property_pictures = data[key];
          var property_pictures_rest = {};
          for (var pictureItem_key in property_pictures) {
            var picture_item = property_pictures[pictureItem_key];
            // if (picture_item.id && !picture_item.isDelete) {
            //     continue;
            // }
            property_pictures_rest[pictureItem_key] = {};
            for (var item_key in picture_item) {
              if (
                item_key === "property_picture_preview" ||
                item_key === "tab_value"
              ) {
                continue;
              }
              if (item_key === "property_images" && picture_item[item_key]) {
                formData.append(
                  item_key,
                  picture_item[item_key],
                  picture_item[item_key].name
                );
              } else {
                property_pictures_rest[pictureItem_key][item_key] =
                  picture_item[item_key];
              }
            }
          }
          formData.append(
            "property_pictures_rest",
            JSON.stringify(property_pictures_rest)
          );
        } else if (key === "property_documents") {
          var property_documents = data[key];
          var property_documents_rest = [];
          for (var doc_item_key in property_documents) {
            var doc_item = property_documents[doc_item_key];
            property_documents_rest[doc_item_key] = {};
            for (var sub_item_key in doc_item) {
              if (
                sub_item_key !== "property_doc_filename" &&
                sub_item_key !== "tab_value"
              ) {
                if (
                  sub_item_key === "property_doc_file" &&
                  doc_item[sub_item_key]
                ) {
                  formData.append(
                    "property_documents",
                    doc_item[sub_item_key],
                    doc_item[sub_item_key].name
                  );
                } else {
                  property_documents_rest[doc_item_key][sub_item_key] =
                    doc_item[sub_item_key];
                }
              }
            }
          }
          formData.append(
            "property_documents_rest",
            JSON.stringify(property_documents_rest)
          );
        }
      }
    }
  }

  formData.delete("macro_location_image_preview");
  formData.delete("micro_location_image_preview");

  const requestOptions = {
    method: "PATCH",
    headers: { ...authHeader() },
    body: formData,
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/properties/${id}`,
    requestOptions
  ).then(handleResponse);
}

function purchaseProperty(data) {
  const id = data.property_id;

  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/properties/purchase/${id}`,
    requestOptions
  ).then(handleResponse);
}

function transferProperty(data) {
  const id = data.property_id;

  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/properties/transfer/${id}`,
    requestOptions
  ).then(handleResponse);
}

async function filterProperty(dataLimit, filterObj, page) {
  var initialStateParams = initialState();
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      limit: dataLimit,
      page: page,
      lng: initialStateParams.languageCode,
      filterQuery: filterObj,
    }),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/properties/filter`,
    requestOptions
  ).then(handleResponse);
}

// PROPERTY SERVICES
function addProperty(data) {
  var formData = new FormData();
  if (data && Object.keys(data).length > 0) {
    for (var key in data) {
      if (!Array.isArray(data[key])) {
        if (key !== "floor_plan_image_preview") {
          if (
            key === "macro_location_image" ||
            key === "micro_location_image"
          ) {
            if (data[key] !== "")
              formData.append(key, data[key], data[key].name);
          } else {
            if (key === "property_location") {
              var property_location = JSON.stringify(data[key]);
              formData.append(key, property_location);
            } else {
              formData.append(key, data[key]);
            }
          }
        }
      } else {
        if (key === "key_figures") {
          var key_figures = JSON.stringify(data[key]);
          formData.append(key, key_figures);
        } else if (key === "sections") {
          var sections = JSON.stringify(data[key]);
          formData.append(key, sections);
        } else if (key === "partners") {
          var partners = JSON.stringify(data[key]);
          formData.append(key, partners);
        } else if (key === "property_floor_plans") {
          var property_floor_plans = data[key];
          var property_floor_plans_rest = property_floor_plans.map((pp) => {
            if (pp.floor_plan_image) {
              formData.append(
                "floor_plan_images",
                pp.floor_plan_image,
                pp.floor_plan_image.name
              );
            }
            const rest = { ...pp };
            delete rest.floor_plan_image;
            delete rest.floor_plan_image_preview;
            return rest;
          });
          formData.append(
            "floor_plans_rest",
            JSON.stringify(property_floor_plans_rest)
          );
        } else if (key === "property_picture") {
          const property_pictures = data[key];
          const property_pictures_rest = property_pictures.map((pp) => {
            if (pp.property_images) {
              formData.append(
                "property_images",
                pp.property_images,
                pp.property_images.name
              );
            }
            const rest = { ...pp };
            delete rest.property_picture_preview;
            delete rest.tab_value;
            delete rest.property_images;
            return rest;
          });
          formData.append(
            "property_pictures_rest",
            JSON.stringify(property_pictures_rest)
          );
        } else if (key === "property_documents") {
          const property_documents = data[key];
          const property_documents_rest = property_documents.map((pd) => {
            if (pd.property_doc_file) {
              formData.append(
                "property_documents",
                pd.property_doc_file,
                pd.property_doc_file.name
              );
            }
            const rest = { ...pd };
            delete rest.property_doc_filename;
            delete rest.tab_value;
            delete rest.property_doc_file;
            return rest;
          });
          formData.append(
            "property_documents_rest",
            JSON.stringify(property_documents_rest)
          );
        }
      }
    }
  }

  formData.delete("macro_location_image_preview");
  formData.delete("micro_location_image_preview");

  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
    body: formData,
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/properties`,
    requestOptions
  ).then(handleResponse);
}

function verifyPaymentDetails(propertyId, investmentDetailObj) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({
      investment_detail: investmentDetailObj,
      propertyId,
    }),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/properties/verify-payment-detail`,
    requestOptions
  ).then(handleResponse);
}

async function getCryptoList() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/crypto`,
    requestOptions
  );
  const text = await response.text();
  try {
    const data = text && JSON.parse(text);
    return data;
  } catch (e) {
    throw "Not Found";
  }
}

async function getCurrencyRates() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/currency`,
    requestOptions
  );
  const text = await response.text();
  try {
    const data = text && JSON.parse(text);
    return data;
  } catch (e) {
    throw "Not Found";
  }
}

function getAvailableLocations(allLocations = false) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };

  let route = `${process.env.REACT_APP_API_URL}/locations`;
  if (allLocations) {
    route += `?all=${true}`;
  }

  return fetch(route, requestOptions).then(handleResponse);
}

// FINANCIAL INTRUMENTS SERVICES
function getFinancialInstruments() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/properties/financial-instruments?languageCode=` +
      initialState().languageCode,
    requestOptions
  ).then(handleResponse);
}

// DIVIDEND DISTRIBUTIONS SERVICES
function getDividendDistributions() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/properties/dividend-distributions?languageCode=` +
      initialState().languageCode,
    requestOptions
  ).then(handleResponse);
}

function getAdminStatistic() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/admin/statistic`,
    requestOptions
  ).then(handleResponse);
}

function hashDocuments(propertyId, propertyDocuments) {
  const bodyData = { property_documents: propertyDocuments };

  const requestOptions = {
    method: "PATCH",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(bodyData),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/properties/${propertyId}/hash_documents`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    try {
      const data = text && JSON.parse(text);
      if (response.status === 451) {
        window.location = "/users/kyc";
      }
      if (!response.ok) {
        if (response.status === 401) {
          userService.logout();
        }
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      return data;
    } catch (e) {
      return Promise.reject("Not Found");
    }
  });
}

function uploadPropertyImage(propertyId, file) {
  var formData = new FormData();
  formData.append("image", file);
  formData.append("property_id", propertyId);
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
    body: formData,
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/property/upload-image/${propertyId}`,
    requestOptions
  ).then(handleResponse);
}

async function scrapeYachts(scrapeUrl) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ scrapeUrl }),
  };
  return fetch(
    `${process.env.REACT_APP_SCRAPER_URL}yachts`,
    requestOptions
  ).then(handleResponse);
}

async function scrapePlanes(scrapeUrl) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ scrapeUrl }),
  };
  return fetch(
    `${process.env.REACT_APP_SCRAPER_URL}planes`,
    requestOptions
  ).then(handleResponse);
}

async function scrapeYacht(scrapeUrl) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ scrapeUrl }),
  };
  return fetch(
    `${process.env.REACT_APP_SCRAPER_URL}yacht`,
    requestOptions
  ).then(handleResponse);
}

async function scrapePlane(id) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/plane/${id}`,
    requestOptions
  ).then(handleResponse);
}

async function fetchYachts(queryString) {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/yachts${queryString}`,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );
  return handleResponse(response);
}

async function fetchYacht(id) {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/yachts/${id}`,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );
  return handleResponse(response);
}
