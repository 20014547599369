import React, { useEffect, useState } from "react";
import { Button, FormControl, TextField } from "@material-ui/core";

const PriceFilter = ({ params }) => {
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");

  useEffect(() => {
    if (params.price) {
      const [min, max] = params.price.split(",").map((num) => num.trim());
      setMinPrice(min || "");
      setMaxPrice(max || "");
    }
  }, [params]);

  const handleApply = () => {
    const currentUrl = window.location.href;
    const baseUrl = currentUrl.split("?")[0];
    const queryParams = new URLSearchParams(window.location.search);

    // Remove existing page parameter
    queryParams.delete("page");
    // Remove price parameter if empty values
    if (!minPrice && !maxPrice) {
      queryParams.delete("price");
    } else {
      // Set the new price parameter
      const price = [minPrice, maxPrice].join(",");
      queryParams.set("price", price);
    }

    const newUrl = `${baseUrl}?${queryParams.toString()}`;
    window.location.href = newUrl;
  };

  const handleReset = () => {
    setMinPrice("");
    setMaxPrice("");
  };

  return (
    <FormControl className="yacht-form-control-wrapper">
      <div className="range-header">
        <p>Price Range</p>
      </div>
      <div className="yacht-filter-form-fields-wrapper">
        <TextField
          className="yacht-filter-fields"
          label="Min Price"
          value={minPrice}
          onChange={(e) => setMinPrice(e.target.value)}
          type="number"
          variant="outlined"
          size="small"
          name="price-min"
        />
        <TextField
          className="yacht-filter-fields"
          label="Max Price"
          value={maxPrice}
          onChange={(e) => setMaxPrice(e.target.value)}
          type="number"
          variant="outlined"
          size="small"
          name="price-max"
        />
      </div>
      <div className="yacht-filter-form-btn-wrapper">
        <Button onClick={handleReset} className="btn-delete">
          Reset
        </Button>
        <Button onClick={handleApply} className="btn-save">
          Apply
        </Button>
      </div>
    </FormControl>
  );
};

export default PriceFilter;
