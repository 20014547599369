import React from "react";
import { Routes, Route } from "react-router-dom";
import HeaderComponent from "../components/HeaderComponent";
import FooterComponent from "../components/FooterComponent";
import HomePage from "../pages/HomePage";
import NotFound from "../pages/NotFound";
import { LoginPage } from "../pages/LoginPage";
import { RegistrationPage } from "../pages/RegistrationPage";
import { ResetPasswordPage } from "../pages/ResetPasswordPage";
import { PropertyDetailPage } from "../pages/PropertyDetailsPage";
import { SearchPage } from "../pages/SearchPage";
import { TermsOfUsePage } from "../pages/TermsOfUse";
import { FrequentlyAskedQuestions } from "../pages/FrequentlyAskedQuestions";
// import { UnderConstruction } from '../pages/UnderConstruction';
import AboutUs from "../pages/AboutUs";
import HowItWorks from "../pages/HowItWorks";
import Listing from "../pages/Listing";
import Escrow from "../pages/Escrow";
import Loans from "../pages/Loans";
import Welcome from "../pages/Welcome";
import ContactPage from "../pages/ContactPage";
import NewHomePage from "../pages/NewHomePage";
// import YachtList from "../components/Yachts/YachtList";
import YachtList from "../components/Yachts/YachtListNew";
// import Yacht from "../components/Yachts/Yacht";
import Yacht from "../components/Yachts/YachtNew";
import PlaneList from "../components/Planes/PlaneList";
import Plane from "../components/Planes/Plane";

const GuestRoute = ({ logopath, pagebackground, logobackground }) => {
  return (
    <Routes>
      <Route
        path="/login"
        element={
          <>
            <HeaderComponent
              logopath={logopath}
              logobackground={logobackground}
            />
            <LoginPage />
            <FooterComponent />
          </>
        }
      />
      <Route
        path="/register"
        element={
          <>
            <HeaderComponent
              logopath={logopath}
              logobackground={logobackground}
            />
            <RegistrationPage />
            <FooterComponent />
          </>
        }
      />
      <Route
        path="/reset-password"
        element={
          <>
            <HeaderComponent
              logopath={logopath}
              logobackground={logobackground}
            />
            <ResetPasswordPage />
            <FooterComponent />
          </>
        }
      />
      <Route
        path="/property/view/:id"
        element={
          <>
            <HeaderComponent
              logopath={logopath}
              logobackground={logobackground}
            />
            <PropertyDetailPage />
          </>
        }
      />
      <Route
        path="/marketplace"
        element={
          <>
            <div className="fixed">
              <HeaderComponent
                logopath={logopath}
                logobackground={logobackground}
              />
            </div>
            <SearchPage />
          </>
        }
      />
      <Route
        path="/terms-of-use"
        element={
          <>
            <div className="fixed">
              <HeaderComponent
                logopath={logopath}
                logobackground={logobackground}
              />
            </div>
            <TermsOfUsePage />
            <FooterComponent />
          </>
        }
      />
      <Route
        path="/faq"
        element={
          <>
            <div className="fixed">
              <HeaderComponent
                logopath={logopath}
                logobackground={logobackground}
              />
            </div>
            <FrequentlyAskedQuestions />
            <FooterComponent />
          </>
        }
      />
      <Route
        path="/contact-us"
        element={
          <>
            <div className="fixed">
              <HeaderComponent
                logopath={logopath}
                logobackground={logobackground}
              />
            </div>
            <ContactPage />
            <FooterComponent />
          </>
        }
      />
      <Route
        path="/yachts-for-sale"
        element={
          <>
            <div className="fixed">
              <HeaderComponent
                logopath={logopath}
                logobackground={logobackground}
              />
            </div>
            <YachtList />
            <FooterComponent />
          </>
        }
      />
      <Route
        path="/yacht/:id"
        element={
          <>
            <div className="fixed">
              <HeaderComponent
                logopath={logopath}
                logobackground={logobackground}
              />
            </div>
            <Yacht />
            <FooterComponent />
          </>
        }
      />
      <Route
        path="/planes-for-sale"
        element={
          <>
            <div className="fixed">
              <HeaderComponent
                logopath={logopath}
                logobackground={logobackground}
              />
            </div>
            <PlaneList />
            <FooterComponent />
          </>
        }
      />
      <Route
        path="/plane/:id"
        element={
          <>
            <div className="fixed">
              <HeaderComponent
                logopath={logopath}
                logobackground={logobackground}
              />
            </div>
            <Plane />
            <FooterComponent />
          </>
        }
      />
      <Route
        path="/get-started/:type"
        element={
          <>
            <div className="fixed">
              <HeaderComponent
                logopath={logopath}
                logobackground={logobackground}
              />
            </div>
            <Welcome />
            <FooterComponent />
          </>
        }
      />
      <Route
        path="/about"
        element={
          <>
            <div className="fixed">
              <HeaderComponent
                logopath={logopath}
                logobackground={logobackground}
              />
            </div>
            <AboutUs />
            <FooterComponent />
          </>
        }
      />
      <Route
        path="/how-it-works"
        element={
          <>
            <div className="fixed">
              <HeaderComponent
                logopath={logopath}
                logobackground={logobackground}
              />
            </div>
            <HowItWorks />
            <FooterComponent />
          </>
        }
      />
      <Route
        path="/listing"
        element={
          <>
            <div className="fixed">
              <HeaderComponent
                logopath={logopath}
                logobackground={logobackground}
              />
            </div>
            <Listing />
            <FooterComponent />
          </>
        }
      />
      <Route
        path="/escrow"
        element={
          <>
            <div className="fixed">
              <HeaderComponent
                logopath={logopath}
                logobackground={logobackground}
              />
            </div>
            <Escrow />
            <FooterComponent />
          </>
        }
      />
      <Route
        path="/loans"
        element={
          <>
            <div className="fixed">
              <HeaderComponent
                logopath={logopath}
                logobackground={logobackground}
              />
            </div>
            <Loans />
            <FooterComponent />
          </>
        }
      />
      {/* <Route exact path="/" element={<UnderConstruction />} /> */}
      <Route
        exact
        path="/"
        element={
          <>
            <HeaderComponent
              logopath={logopath}
              logobackground={logobackground}
              stickyPosition={200}
              noStickyClass="homeNoStickedHeader"
            />
            <NewHomePage />
            {/* <HomePage background={pagebackground} /> */}
            <FooterComponent />
          </>
        }
      />
      <Route
        path="*"
        element={
          <>
            <HeaderComponent
              logopath={logopath}
              logobackground={logobackground}
              stickyPosition={200}
              noStickyClass="homeNoStickedHeader"
            />
            <NotFound />
            <FooterComponent />
          </>
        }
      />
    </Routes>
  );
};

export default GuestRoute;
export { GuestRoute };
