import { removePageParam } from "../../helpers/helpers";

// Render pagination links using the new pagination object.
const renderPagination = ({ handlePaginationClick, pagination }) => {
  if (!pagination.totalPages) return null;

  const { page, totalPages } = pagination;
  const baseQuery = removePageParam(window.location.search);
  // Determine if the baseQuery already has a ? parameter
  const separator = baseQuery.includes("?") ? "&" : "?";

  const links = [];

  // Previous link
  if (page === 1) {
    links.push(
      <span key="prev" className="disabled prev">
        ‹‹ previous
      </span>
    );
  } else {
    links.push(
      <a
        key="prev"
        href={`${baseQuery}${separator}page=${page - 1}`}
        className="prev"
        onClick={(e) =>
          handlePaginationClick(`${baseQuery}${separator}page=${page - 1}`, e)
        }
      >
        ‹‹ previous
      </a>
    );
  }

  // Always show first page if current page is greater than 3
  if (page > 3) {
    links.push(
      <a
        key={1}
        href={`${baseQuery}${separator}page=1`}
        className="page"
        onClick={(e) =>
          handlePaginationClick(`${baseQuery}${separator}page=1`, e)
        }
      >
        1
      </a>
    );
    if (page > 4) {
      links.push(
        <span key="start-ellipsis" className="ellipsis">
          ...
        </span>
      );
    }
  }

  // Show a range of pages: from max(1, page-1) to min(totalPages, page+2)
  const startPage = Math.max(1, page - 1);
  const endPage = Math.min(totalPages, page + 2);
  for (let i = startPage; i <= endPage; i++) {
    if (i === page) {
      links.push(
        <span key={i} className="current page">
          {i}
        </span>
      );
    } else {
      links.push(
        <a
          key={i}
          href={`${baseQuery}${separator}page=${i}`}
          className="page"
          onClick={(e) =>
            handlePaginationClick(`${baseQuery}${separator}page=${i}`, e)
          }
        >
          {i}
        </a>
      );
    }
  }

  // Always show last page if current page is less than totalPages - 2
  if (page < totalPages - 2) {
    if (page < totalPages - 3) {
      links.push(
        <span key="end-ellipsis" className="ellipsis">
          ...
        </span>
      );
    }
    links.push(
      <a
        key={totalPages}
        href={`${baseQuery}${separator}page=${totalPages}`}
        className="page"
        onClick={(e) =>
          handlePaginationClick(`${baseQuery}${separator}page=${totalPages}`, e)
        }
      >
        {totalPages}
      </a>
    );
  }

  // Next link
  if (page === totalPages) {
    links.push(
      <span key="next" className="disabled next">
        next ››
      </span>
    );
  } else {
    links.push(
      <a
        key="next"
        href={`${baseQuery}${separator}page=${page + 1}`}
        className="next"
        onClick={(e) =>
          handlePaginationClick(`${baseQuery}${separator}page=${page + 1}`, e)
        }
      >
        next ››
      </a>
    );
  }
  return links;
};

export default renderPagination;
