import React from "react";
import { Button } from "@material-ui/core";
import PlaneBuyImg from "../../assets/images/planes-buy.jpg";
import "./styles.scss";

const PlaneSection = () => {
  return (
    <section className="yachts-section">
      <div className="yacht-section-container">
        <div className="yacht-section-row planes-section-row">
          <div className="yacht-section-col content-col">
            <div className="yacht-section-content">
              <h3>FIND YOUR NEXT AIRCRAFT</h3>
              <p>Search and explore our luxury planes</p>
              <Button
                onClick={() => (window.location.href = "/planes-for-sale")}
                className="show-more-btn"
              >
                Explore
              </Button>
            </div>
          </div>
          <div className="yacht-section-col">
            <img src={PlaneBuyImg} alt="planes buy" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default PlaneSection;
