import React, { useEffect, useState } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
  Button,
  ListSubheader,
} from "@material-ui/core";
import { regionData } from "../yacht-types";

const RegionFilter = ({ params }) => {
  const [selectedCountries, setSelectedCountries] = useState([]);

  useEffect(() => {
    if (params.region) {
      // Expect region parameter as a comma-separated list.
      const countries = params.region.split(",").filter(Boolean);
      setSelectedCountries(countries);
    }
  }, [params]);

  const handleChange = (event) => {
    setSelectedCountries(event.target.value);
  };

  const handleReset = () => {
    setSelectedCountries([]);
  };

  const handleApply = () => {
    const currentUrl = window.location.href;
    const baseUrl = currentUrl.split("?")[0];
    const queryParams = new URLSearchParams(window.location.search);
    // Remove the page parameter so new results start on page 1
    queryParams.delete("page");
    // Set the region parameter as a comma-separated string
    queryParams.set("region", selectedCountries.join(","));
    const newUrl = `${baseUrl}?${queryParams.toString()}`;
    window.location.href = newUrl;
  };

  return (
    <FormControl className="yacht-form-control-wrapper">
      <p>Region</p>
      <div className="yacht-filter-form-fields-wrapper">
        <Select
          labelId="region-select-label"
          id="region-select"
          multiple
          value={selectedCountries}
          onChange={handleChange}
          className="yacht-filter-select-field"
          variant="outlined"
          displayEmpty
          renderValue={(selected) => {
            if (selected.length === 0) {
              return "Select Region";
            }
            return selected.join(", ");
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 400,
                width: 300,
              },
            },
          }}
        >
          {regionData
            .map((group) => [
              <ListSubheader key={`${group.continent}-header`}>
                {group.continent}
              </ListSubheader>,
              ...group.countries.map((country) => (
                <MenuItem key={country} value={country}>
                  <Checkbox checked={selectedCountries.indexOf(country) > -1} />
                  <ListItemText primary={country} />
                </MenuItem>
              )),
            ])
            .flat()}
        </Select>
      </div>
      <div className="yacht-filter-form-btn-wrapper">
        <Button className="btn-delete" onClick={handleReset}>
          Reset
        </Button>
        <Button className="btn-save" onClick={handleApply}>
          Apply
        </Button>
      </div>
    </FormControl>
  );
};

export default RegionFilter;
