import React, { useState, useEffect } from "react";
import { generalService } from "../../_services/general.service";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import PlaneListSkeletonLoader from "./Loaders/PlaneListSkeletonLoader";
import renderPagination from "../Yachts/Filters/YachtPagination";
import PlaneFilters from "./PlaneFilters";
import PlaneSort from "./PlaneSort";
import "./styles.scss";

const PlaneList = () => {
  const [planes, setPlanes] = useState([]);
  const [pagination, setPagination] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [params, setParams] = React.useState(false);
  const [categories, setCategories] = React.useState(false);
  // console.log("params: ", params);
  // console.log("window.location.search: ", window.location.search);
  // console.log("planes: ", planes);
  // console.log("pagination: ", pagination);

  const navigate = useNavigate();

  const fetchPlanes = (scrapeUrl) => {
    setLoading(true);
    generalService
      .scrapePlanes(scrapeUrl)
      .then((response) => {
        setPlanes(response.data);
        setPagination(response.pagination);
        setCategories(response.categories);
        setLoading(false);
      })
      .catch((error) => {
        setError("Error fetching planes");
        setLoading(false);
      });
  };

  useEffect(() => {
    const queryString = window.location.search; // Get query string from the URL
    console.log("queryString", queryString);
    fetchPlanes(`${queryString}`); // Send query string to backend

    // Create a URLSearchParams object
    const urlParams = new URLSearchParams(queryString);

    // Convert the URLSearchParams object into a plain object
    setParams(Object.fromEntries(urlParams.entries()));
  }, [window.location.search]);

  // Function to handle pagination click
  const handlePaginationClick = (url, e) => {
    e.preventDefault();
    window.history.pushState(null, "", url);
    fetchPlanes(url);
  };

  if (loading) return <PlaneListSkeletonLoader />;
  if (error) return <div>{error}</div>;

  return (
    <div style={{ marginBottom: "32px" }}>
      <section className="yacht-hero-section">
        <div className="yacht-hero-container plane-hero-container">
          <h3>Search Planes For Sale</h3>
          <div className="yacht-filters-wrapper">
            <PlaneFilters params={params} categories={categories} />
          </div>
          {/* <YachtSearchField /> */}
        </div>
      </section>

      {/* Pagination Section at Top */}
      <section className="pagination-container">
        <div className="pagination-wrapper">
          {renderPagination({ handlePaginationClick, pagination })}
        </div>
        <PlaneSort />
      </section>

      {/* Render Plane List */}
      <section className="yacht-list">
        <div className="yacht-list-container">
          <Grid container spacing={2}>
            {planes.map((plane, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  onClick={() => navigate(`/plane/${plane.id}`)}
                  className="yacht-card"
                >
                  <CardMedia
                    component="img"
                    height="330"
                    image={plane.image}
                    alt={plane.title}
                    srcSet={plane.image}
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      className="yacht-name"
                      component="div"
                    >
                      {plane.title}
                    </Typography>
                    <div
                      className="yacht-card-price plane-card-price"
                      dangerouslySetInnerHTML={{ __html: plane.price }}
                    />
                    {plane.featured ? (
                      <div className="yacht-card-price featured">
                        featured
                      </div>
                    ) : null}
                    <div className="yacht-details">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: plane.category,
                        }}
                      />
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      </section>

      {/* Pagination Section at Top */}
      <section className="pagination-container">
        <div className="pagination-wrapper">
          {renderPagination({ handlePaginationClick, pagination })}
        </div>
        {/* <YachtSort /> */}
      </section>
    </div>
  );
};

export default PlaneList;
