import React from "react";
import Popper from "@material-ui/core/Popper";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import PriceFilter from "./Filters/PriceFilter";
import CategoryFilter from "./Filters/CategoryFilter";
import YearFilter from "./Filters/YearFilter";
import MakeModelFilter from "./Filters/MakeModelFilter";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const PlaneFilters = ({ params, categories }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [activeFilter, setActiveFilter] = React.useState(null);

  const handleClick = (filter) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => activeFilter !== filter || !prev);
    setActiveFilter(filter);
  };

  return (
    <div className="yacht-filters plane-filters">
      <div className="yacht-filters-wrapper plane-filters-wrapper">
        <Button
          className="btn-filter"
          endIcon={
            activeFilter === "category" ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )
          }
          onClick={handleClick("category")}
        >
          category
        </Button>
        <Button
          className="btn-filter"
          endIcon={
            activeFilter === "make_model" ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )
          }
          onClick={handleClick("make_model")}
        >
          make / model
        </Button>
        <Button
          className="btn-filter"
          endIcon={
            activeFilter === "year" ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )
          }
          onClick={handleClick("year")}
        >
          year
        </Button>
        <Button
          className="btn-filter"
          endIcon={
            activeFilter === "price" ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )
          }
          onClick={handleClick("price")}
        >
          price
        </Button>
      </div>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              {activeFilter === "price" && <PriceFilter params={params} />}
              {activeFilter === "year" && <YearFilter params={params} />}
              {activeFilter === "category" && (
                <CategoryFilter params={params} categories={categories.data} />
              )}
              {activeFilter === "make_model" && (
                <MakeModelFilter params={params} />
              )}
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default PlaneFilters;
