import React, { useState, useEffect } from "react";
import "./styles.scss";
import { generalService } from "../../_services/general.service";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import YachtFilters from "./YachtFiltersNew";
import YachtSearchField from "./Filters/YachtSearchFieldNew";
import YachtListSkeletonLoader from "./Loaders/YachtListSkeletonLoader";
import YachtSort from "./Filters/YachtSortNew";
import renderPagination from "./Filters/YachtPagination";
import { yachtsConstants } from "../../_constants";

const YachtList = () => {
  const [yachts, setYachts] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [params, setParams] = useState({});
  const navigate = useNavigate();

  // New fetchYachts endpoint
  const fetchYachts = (queryString) => {
    setLoading(true);
    generalService
      .fetchYachts(queryString)
      .then((response) => {
        setYachts(response.data);
        setPagination(response.pagination);
        setLoading(false);
      })
      .catch((error) => {
        setError("Error fetching yachts");
        setLoading(false);
      });
  };

  useEffect(() => {
    const queryString = window.location.search;
    fetchYachts(queryString);
    const urlParams = new URLSearchParams(queryString);
    setParams(Object.fromEntries(urlParams.entries()));
  }, [window.location.search]);

  const handlePaginationClick = (url, e) => {
    e.preventDefault();
    window.history.pushState(null, "", url);
    fetchYachts(url);
  };

  const getCoverImage = (yacht) => {
    const imgPath = yacht.yacht_images[0]?.image_path;
    return !imgPath || imgPath.includes("cdn.yachtbroker.org")
      ? yachtsConstants.PLACEHOLDER_IMAGE
      : imgPath;
  };

  if (loading) return <YachtListSkeletonLoader />;
  if (error) return <div>{error}</div>;

  return (
    <div style={{ marginBottom: "32px" }}>
      <section className="yacht-hero-section">
        <div className="yacht-hero-container">
          <h3>Search Yachts For Sale</h3>
          <div className="yacht-filters-wrapper">
            <YachtFilters params={params} />
          </div>
          <YachtSearchField />
        </div>
      </section>

      {/* Pagination Section at Top */}
      <section className="pagination-container">
        <div className="pagination-wrapper">
          {renderPagination({ handlePaginationClick, pagination })}
        </div>
        <YachtSort />
      </section>

      {/* Yacht List Section */}
      <section className="yacht-list">
        <div className="yacht-list-container">
          <Grid container spacing={2}>
            {yachts.map((yacht, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  onClick={() => navigate(`/yacht/${yacht.id}`)}
                  className="yacht-card"
                >
                  <CardMedia
                    component="img"
                    height="330"
                    image={getCoverImage(yacht)}
                    alt={yacht.vessel_name}
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      className="yacht-name"
                      component="div"
                    >
                      {yacht.vessel_name || "No Name"}
                    </Typography>
                    <div
                      className="yacht-card-price"
                      dangerouslySetInnerHTML={{ __html: yacht.price }}
                    />
                    <div className="yacht-details">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: yacht.length_display_feet,
                        }}
                      />
                      {yacht.vessel_manufacturer && (
                        <>
                          <span>|</span>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: yacht.vessel_manufacturer,
                            }}
                          />
                        </>
                      )}
                      {yacht.year && (
                        <>
                          <span>|</span>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: yacht.year,
                            }}
                          />
                        </>
                      )}
                    </div>
                    <div className="yacht-rooms">
                      {yacht.cabin_count > 0 && (
                        <span>{yacht.cabin_count} Staterooms</span>
                      )}
                      {yacht.sleep_count > 0 && (
                        <>
                          |<span>{yacht.sleep_count} Guests</span>
                        </>
                      )}
                      {yacht.crew_sleep_count > 0 && (
                        <>
                          |<span>{yacht.crew_sleep_count} Crew</span>
                        </>
                      )}
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      </section>

      {/* Pagination Section at Bottom */}
      <section className="pagination-container">
        <div className="pagination-wrapper">
          {renderPagination({ handlePaginationClick, pagination })}
        </div>
      </section>
    </div>
  );
};

export default YachtList;
