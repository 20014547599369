import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const YachtSort = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // Use "sortField" and "sortOrder" from the query string.
  const initialSortField = searchParams.get("sortField") || "";
  const initialSortOrder = searchParams.get("sortOrder") || "";
  // Reconstruct the sort value for the select.
  const initialSort =
    initialSortOrder === "asc" ? `-${initialSortField}` : initialSortField;

  const [sort, setSort] = useState(initialSort);

  const handleSortChange = (e) => {
    const newSort = e.target.value;
    setSort(newSort);
    if (newSort) {
      // Remove page parameter
      searchParams.delete("page");

      // Remove any dash to get the field name.
      searchParams.set("sortField", newSort.replace("-", ""));

      // If the new value starts with "-", set order to ascending; otherwise, default descending.
      if (newSort.startsWith("-")) {
        searchParams.set("sortOrder", "asc");
      } else {
        searchParams.delete("sortOrder");
      }
    } else {
      searchParams.delete("sortField");
      searchParams.delete("sortOrder");
    }
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sortField = params.get("sortField") || "";
    const sortOrder = params.get("sortOrder") || "";
    const currentSort = sortOrder === "asc" ? `-${sortField}` : sortField;
    setSort(currentSort);
  }, [location.search]);

  return (
    <div id="nestyachts-sort">
      <select value={sort} onChange={handleSortChange}>
        <option value="">Default Sort</option>
        <option value="price">Price (High-Low)</option>
        <option value="-price">Price (Low-High)</option>
        <option value="length">Length (Long-Short)</option>
        <option value="-length">Length (Short-Long)</option>
        <option value="year">Year (New-Old)</option>
        <option value="-year">Year (Old-New)</option>
      </select>
    </div>
  );
};

export default YachtSort;
