import React, { useState, useEffect } from "react";
import { Button, FormControl, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "../styles.scss";

const MakeModelFilter = ({ params }) => {
  // State for maker search and results
  const [makerInput, setMakerInput] = useState("");
  const [makerOptions, setMakerOptions] = useState([]);
  const [selectedMaker, setSelectedMaker] = useState(null);
  const [prePopulate, setPrePopulate] = useState(false); // Track pre-population state

  // State for model family
  const [modelFamilyOptions, setModelFamilyOptions] = useState([]);
  const [selectedModelFamily, setSelectedModelFamily] = useState(null);

  // State for model select
  const [modelOptions, setModelOptions] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);

  // Pre-populate fields if URL contains pub_make, pub_model_family, and pub_model
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const pubMake = queryParams.get("pub_make");
    const pubModelFamily = queryParams.get("pub_model_family");
    const pubModel = queryParams.get("pub_model");

    if (pubMake && pubModelFamily && pubModel) {
      fetch(
        `${
          process.env.REACT_APP_API_URL
        }/planes/categories-maker-model-by-ids?maker_id=${pubMake}&model_id=${pubModel}&model_family_id=${encodeURIComponent(
          pubModelFamily
        )}`
      )
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            const maker = {
              value: data.data.pub_make.value,
              label: data.data.pub_make.label,
            };
            const modelFamily = {
              value: data.data.pub_model_family.value,
              label: data.data.pub_model_family.label,
            };
            const model = {
              value: data.data.pub_model.value,
              label: data.data.pub_model.label,
            };

            setPrePopulate(true); // Mark that we've pre-populated
            setSelectedMaker(maker);
            setSelectedModelFamily(modelFamily);
            setSelectedModel(model);
            setMakerInput(maker.label);
            setPrePopulate(false);
          }
        })
        .catch((err) =>
          console.error("Error pre-populating make/model filters:", err)
        );
    }
  }, []);

  // Debounce maker search: only trigger when input has at least 3 characters
  useEffect(() => {
    if (!prePopulate) {
      const delayDebounceFn = setTimeout(() => {
        if (makerInput.length >= 3) {
          fetch(
            `${
              process.env.REACT_APP_API_URL
            }/planes/makers?val=${encodeURIComponent(makerInput)}`
          )
            .then((res) => res.json())
            .then((data) => {
              setMakerOptions(data.data);
            })
            .catch((err) => console.error("Error fetching makers:", err));
        } else {
          setMakerOptions([]);
        }
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [makerInput]);

  // When a maker is selected, fetch model families
  useEffect(() => {
    if (!prePopulate && selectedMaker) {
      fetch(
        `${process.env.REACT_APP_API_URL}/planes/model-families?maker_id=${selectedMaker.value}`
      )
        .then((res) => res.json())
        .then((data) => {
          setModelFamilyOptions(data.data);
          setSelectedModelFamily(null);
          setModelOptions([]);
          setSelectedModel(null);
        })
        .catch((err) => console.error("Error fetching model families:", err));
    } else if (prePopulate) {
      setModelFamilyOptions([]);
      setSelectedModelFamily(null);
      setModelOptions([]);
      setSelectedModel(null);
    }
  }, [selectedMaker]);

  // When a model family is selected, fetch models
  useEffect(() => {
    if (!prePopulate && selectedMaker && selectedModelFamily) {
      fetch(
        `${process.env.REACT_APP_API_URL}/planes/models?maker_id=${
          selectedMaker.value
        }&mf=${encodeURIComponent(selectedModelFamily.value)}`
      )
        .then((res) => res.json())
        .then((data) => {
          setModelOptions(data.data);
          setSelectedModel(null);
        })
        .catch((err) => console.error("Error fetching models:", err));
    } else if (prePopulate) {
      setModelOptions([]);
      setSelectedModel(null);
    }
  }, [selectedMaker, selectedModelFamily]);

  // Handler for Apply button – updates URL params (and resets page to 1)
  const handleApply = () => {
    const currentUrl = window.location.href;
    const baseUrl = currentUrl.split("?")[0];
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.delete("page"); // reset page
    if (selectedMaker) {
      queryParams.set("pub_make", selectedMaker.value);
    } else {
      queryParams.delete("pub_make");
    }
    if (selectedModelFamily) {
      queryParams.set("pub_model_family", selectedModelFamily.value);
    } else {
      queryParams.delete("pub_model_family");
    }
    if (selectedModel) {
      queryParams.set("pub_model", selectedModel.value);
    } else {
      queryParams.delete("pub_model");
    }
    window.location.href = `${baseUrl}?${queryParams.toString()}`;
  };

  const handleReset = () => {
    setMakerInput("");
    setMakerOptions([]);
    setSelectedMaker(null);
    setModelFamilyOptions([]);
    setSelectedModelFamily(null);
    setModelOptions([]);
    setSelectedModel(null);
    const currentUrl = window.location.href;
    const baseUrl = currentUrl.split("?")[0];
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.delete("pub_make");
    queryParams.delete("pub_model_family");
    queryParams.delete("pub_model");
    queryParams.delete("page");
    window.location.href = `${baseUrl}?${queryParams.toString()}`;
  };

  return (
    <FormControl className="yacht-form-control-wrapper">
      <p>Make / Model</p>
      <div className="yacht-filter-form-fields-wrapper plane-filter-form-fields-wrapper column">
        {/* Maker Autocomplete Field */}
        <div>
          <Autocomplete
            value={selectedMaker}
            onChange={(event, newValue) => setSelectedMaker(newValue)}
            inputValue={makerInput}
            onInputChange={(event, newInputValue) =>
              setMakerInput(newInputValue)
            }
            options={makerOptions}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Make"
                variant="outlined"
                size="small"
                className="yacht-filter-fields make-models-filter"
              />
            )}
          />
          <small>Type at least 3 characters</small>
        </div>
        {/* Show Model Family select only if maker is selected */}
        {selectedMaker && (
          <Autocomplete
            value={selectedModelFamily}
            onChange={(event, newValue) => setSelectedModelFamily(newValue)}
            options={modelFamilyOptions}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Model Family"
                variant="outlined"
                size="small"
                className="yacht-filter-fields make-models-filter"
              />
            )}
            style={{ marginTop: "8px" }}
          />
        )}
        {/* Show Model select only if model family is selected */}
        {selectedModelFamily && (
          <Autocomplete
            value={selectedModel}
            onChange={(event, newValue) => setSelectedModel(newValue)}
            options={modelOptions}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Model"
                variant="outlined"
                size="small"
                className="yacht-filter-fields make-models-filter"
              />
            )}
            style={{ marginTop: "8px" }}
          />
        )}
      </div>
      <div className="yacht-filter-form-btn-wrapper">
        <Button onClick={handleReset} className="btn-delete">
          Reset
        </Button>
        <Button onClick={handleApply} className="btn-save">
          Apply
        </Button>
      </div>
    </FormControl>
  );
};

export default MakeModelFilter;
